/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 32번째 로그에서는 국내외 데이터 센터 구축, 국민은행 OTP 유실 사건, 알프레드 4 출시 등에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://events.withgoogle.com/digital-transformation-with-g-suite/"
  }, "Digital Transformation with G Suite - Home")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.theverge.com/2019/5/21/18634842/google-passwords-plain-text-g-suite-fourteen-years"
  }, "Google stored some passwords in plain text for fourteen years - The Verge")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://cloud.watch.impress.co.jp/docs/news/1187142.html"
  }, "(일본어) Google, 일본 첫 데이터센터 건설을 위해 치바뉴타운에 토지 매입")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/tpu/?hl=ko"
  }, "Cloud TPU  |  Google Cloud")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://byline.network/2017/02/1-584/"
  }, "MS, 국내서 클라우드 데이터센터 리전 2개 가동 - Byline Network")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.datanet.co.kr/news/articleView.html?idxno=108972"
  }, "NHN엔터, 데이터센터 홈페이지 오픈…클라우드 사업 강화 - 데이터넷")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.yna.co.kr/view/AKR20130620064800062"
  }, "NHN, 과학기술 집약 춘천 데이터센터 '각' 공개 | 연합뉴스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.yes24.com/Product/goods/14606751"
  }, "플래시 보이스 - YES24")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.v.daum.net/v/20190520173704390"
  }, "네이버 데이터센터 급한데..주민은 반대·용인시는 '나몰라라' | Daum 뉴스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.toast.com/kr"
  }, "토스트(TOAST) 클라우드")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.netflix.com/kr/title/80021955"
  }, "베터 콜 사울 | Netflix 공식 사이트")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.dailymedi.com/detail.php?number=843625"
  }, "대법원, '안아키' 한의사 징역형 확정···상고심 기각 - 데일리메디")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.v.daum.net/v/20190518040313546"
  }, "\"미세먼지 호들갑, 이제는 그만하자\" | Daum 뉴스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.mk.co.kr/news/society/view/2019/04/276896/"
  }, "美, 올해 홍역 700명 돌파 ", React.createElement(_components.code, null, "비상"), "…학교 문닫고 백신 의무화法도 - 매일경제")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.hani.co.kr/arti/science/future/883398.html"
  }, "황당한 ‘지구평면설’…퍼뜨리는 유튜브 추천 시스템 : 미래 : 미래&과학 : 뉴스 : 한겨레")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://byline.network/2018/05/16-15/"
  }, "전길남 vs 빈트서프, 인터넷 아버지들의 미묘한 시각차 - Byline Network")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://news.kmib.co.kr/article/view.asp?arcid=0924073787&code=11151400"
  }, "“국내 클라우드 시장 사수”… 네이버, 아마존·MS에 선전포고-국민일보")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://tmaxos.com/"
  }, "TmaxOS 선택의 기쁨, 혁신의 시작")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.winehq.org/"
  }, "WineHQ - Run Windows applications on Linux, BSD, Solaris and macOS")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EB%8F%84%EC%BF%84_%EA%B3%B5%EC%97%85%EB%8C%80%ED%95%99"
  }, "도쿄 공업대학 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://iphone-mania.jp/news-248343/"
  }, "(일본어) 도쿄공업대학 신입생의 76.0%가 iPhone사용. 조사 개시 이후 최고치 - iPhone Mania")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://utcc.utoronto.ca/~cks/space/blog/programming/GoIsGooglesLanguage"
  }, "Chris's Wiki :: blog/programming/GoIsGooglesLanguage")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.ciokorea.com/news/123354"
  }, "칼럼 | 쿠버네티스 생태계의 'VM웨어 급부상'이 반가운 이유 - CIO Korea")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Richard_Stallman"
  }, "Richard Stallman - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.gnu.org/software/repo-criteria-evaluation.html#GitHub"
  }, "GNU Ethical Repository Criteria Evaluations - GNU Project - Free Software Foundation")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://pragprog.com/book/vbopens/forge-your-future-with-open-source"
  }, "Forge Your Future with Open Source: Build Your Skills. Build Your Network. Build the Future of Technology. by VM (Vicky) Brasseur | The Pragmatic Bookshelf")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.blog/2019-04-29-apache-joins-github-community/"
  }, "Apache Software Foundation joins GitHub open source community - The GitHub Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blogs.windows.com/msedgedev/2019/05/20/microsoft-edge-macos-canary-preview/"
  }, "Introducing the first Microsoft Edge preview builds for macOS - Microsoft Edge Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.ghacks.net/2019/05/28/googles-blocking-new-microsoft-edge-from-accessing-new-design/"
  }, "Google's blocking new Microsoft Edge from accessing new design - gHacks Tech News")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://9to5google.com/2019/05/29/chrome-ad-blocking-enterprise-manifest-v3/"
  }, "Chrome to limit full ad blocking extensions to enterprise users - 9to5Google")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://basicattentiontoken.org/"
  }, "Basic Attention Token")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.mozilla.org/ko/firefox/switch/"
  }, "단 몇 분 만에 Chrome에서 Firefox로 전환")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.mozilla.org/firefox/how-to-block-fingerprinting-with-firefox/"
  }, "How to block fingerprinting with Firefox | The Firefox Frontier")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.pycon.kr/"
  }, "PyCon Korea 2019")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://devground.hanbit.co.kr/"
  }, "Home - DevGround")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://newspeppermint.com/2019/05/26/south-korea-fintech/"
  }, "핀테크 등장으로 달라진 한국의 은행업계")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.alfredapp.com/blog/announcements/alfred-4-is-here/"
  }, "Alfred 4 Is Here! - Alfred Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.alfredapp.com/shop/"
  }, "Buy the Alfred Powerpack")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.alfredapp.com/help/features/clipboard/"
  }, "Clipboard History - Alfred Help and Support")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.packal.org/workflow/caffeinate-control"
  }, "Caffeinate Control | Packal")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.packal.org/workflow/google-translate-alfred-workflow"
  }, "Google Translate Alfred Workflow | Packal")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.packal.org/workflow/network-location"
  }, "Network Location | Packal")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.packal.org/workflow/whats-my-ip"
  }, "What's My IP | Packal")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.literatureandlatte.com/scrivener/overview"
  }, "Scrivener | Literature & Latte")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.devontechnologies.com/apps/devonthink"
  }, "DEVONtechnologies | DEVONthink, professional document and information management for the Mac and iOS")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ondemandkorea.bamboohr.com/jobs/"
  }, "ODK Media Career - BambooHR")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
